import { callBackend } from "../callBackend";

export async function getServicePriceList(language) {
  language = language || "all";
  const apiPath = `api/user/analytics?language=${language}`;

  let backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;
  if (!backendDomain) {
    return { success: false, message: "no backendDomain" };
  }

  let responce = await callBackend({
    link: `${backendDomain}/${apiPath}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return responce;
}
