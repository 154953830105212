export default function VideoItem({src, alt, type, previewSrc}) {
  alt ??= "imageitem";
  src ??= "";
  previewSrc ??= "";
  type ??= src.split(".").pop();
  return (
    <li className="media_container m_video" >
      <video controls preload="metadata" poster={previewSrc}>
        <source src={src} type={`video/${type}`} />
      </video>
    </li>
  );
}
