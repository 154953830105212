export const languageNamesTable = {
  ru: {
    ru: "Русский",
    en: "Russian",
  },
  en: {
    ru: "Английский",
    en: "English",
  },
};
