import styles from "../styles/ButtonListSelectWorkerLanguage.module.css";

export default function ButtonListSelectWorkerLanguage({
  languageList,
  selectedLanguage,
  onLanguageChange,
}) {

  if (languageList.length <= 1) {
    languageList = [];
  }

  return (
    <div className={styles.buttonList}>
      {languageList.map((language) => {
        return (
          <button
            key={language}
            className={selectedLanguage === language ? "selected" : ""}
            onClick={() => {
              onLanguageChange(language);
            }}
          >
            {language}
          </button>
        );
      })}
    </div>
  );
}
