import styles from "../styles/ButtonListChangeLocale.module.css";
import React, { useState, useEffect } from "react";

export default function ButtonListChangeLocale({}) {
  const languageCode =
    window?.Telegram?.WebApp?.initDataUnsafe?.user?.language_code;
  const [selectedLocale, setSelectedLocale] = useState(languageCode);

  function buildChangeLocale(localeName) {
    return function () {
      if (window?.Telegram?.WebApp?.initDataUnsafe?.user?.language_code) {
        window.Telegram.WebApp.initDataUnsafe.user.language_code = localeName;
        setSelectedLocale(localeName);
      }
    };
  }

  return (
    <div>
      <p>List localization</p>
      <ul className={styles.buttonList}>
        <li>
          <button
            onClick={buildChangeLocale("ru")}
            className={`${
              selectedLocale === "ru" ? styles.selectedButton : ""
            }`}
          >
            Русский
          </button>
        </li>
        <li>
          <button
            onClick={buildChangeLocale("en")}
            className={`${
              selectedLocale === "en" ? styles.selectedButton : ""
            }`}
          >
            English
          </button>
        </li>
      </ul>
    </div>
  );
}
